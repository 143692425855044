import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'hash',
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'Producción de juzgados',
          path: '/data',
          component: () => import('@/views/dashboard/Sedes'),
        },
        {
          name: 'Producción de juzgado',
          path: 'prod/:id',
          component: () => import('@/views/dashboard/Juzgado'),
        },
        {
          name: 'Producción de secretarios',
          path: 'secretario',
          component: () => import('@/views/dashboard/Secretario'),
        },
        {
          name: 'Impulso procesal',
          path: 'impulso',
          component: () => import('@/views/dashboard/Impulso'),
        },
        {
          name: 'Escritos',
          path: 'escrito',
          component: () => import('@/views/dashboard/Escrito'),
        },
        {
          name: 'Audiencias',
          path: 'audiencia',
          component: () => import('@/views/dashboard/Audiencia'),
        },
        {
          name: 'Notificaciones',
          path: 'notificaciones',
          component: () => import('@/views/dashboard/Notificaciones'),
        },
        {
          name: 'Ingresos',
          path: 'ingresos',
          component: () => import('@/views/dashboard/Ingresos'),
        },
        {
          name: 'Fallecidos',
          path: 'fallecidos',
          component: () => import('@/views/dashboard/Fallecido'),
        },
        {
          name: 'Delito',
          path: 'delito_detalle',
          component: () => import('@/views/dashboard/Delitos'),
        },
        {
          name: 'Delitos',
          path: 'delito',
          component: () => import('@/views/dashboard/DelitosLooker'),
        },
        {
          name: 'Boletin',
          path: 'boletin',
          component: () => import('@/views/dashboard/Boletin'),
        },
        {
          name: 'SentenciasNoBoletin',
          path: 'sentencias_boletin',
          component: () => import('@/views/dashboard/SentenciasSinBoletin'),
        },
        {
          name: 'Edición Delitos',
          path: 'editar_delitos',
          component: () => import('@/views/dashboard/UploadDelitos'),
        },
        {
          name: 'Ranking',
          path: 'ranking',
          component: () => import('@/views/dashboard/Ranking'),
        },
        {
          name: 'Actualizar producción',
          path: 'produccion',
          component: () => import('@/views/dashboard/UploadProduccion'),
        },
        {
          name: 'Actualizar producción anual',
          path: 'up',
          component: () => import('@/views/dashboard/UploadProduccionAnual'),
        },
        {
          name: 'Actualizar juzgados',
          path: 'juzgado',
          component: () => import('@/views/dashboard/UploadJuzgado'),
        },
        {
          name: 'Actualizar metas',
          path: 'metas',
          component: () => import('@/views/dashboard/UploadMetas'),
        },
        {
          name: 'Juzgados',
          path: 'juzgados',
          component: () => import('@/views/dashboard/JuzgadosEdit'),
        },
        {
          name: 'Edición de menú principal',
          path: 'editar_menu',
          component: () => import('@/views/dashboard/EditMenu'),
        },
        {
          name: 'Cerrar sesión',
          path: 'cerrar_sesion',
          component: () => import('@/views/dashboard/Cerrar'),
        },
        {
          name: 'ERROR',
          path: '/404',
          component: () => import('@/views/dashboard/404'),
        },
        {
          name: 'Inicio de sesión',
          path: '*',
          component: () => import('@/views/dashboard/Login'),
        },
      ],
    },
  ],
})

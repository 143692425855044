<template>
  <router-view />
</template>

<script>
  export default {
    name: 'Data',
    created () {
      localStorage.setItem('urlApiData', 'http://dataapi.csjla.pe/rest/')
      localStorage.setItem('sedeCorteData', '17')
    },
  }
</script>

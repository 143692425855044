<template>
  <base-material-card
    :icon="icon"
    class="v-card--material-stats"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div>
      <div
        class="text-right text-h2 teal--text font-weight-bold"
        v-text="title"
      />

      <h4 class="text-right font-weight-light">
        <h4 class="text-right font-weight-light text-partido">
          <v-icon
            v-if="value"
            color="yellow darken-3"
          >
            mdi-scale-balance
          </v-icon> {{ value }}
        </h4>
        <h4 class="text-right font-weight-bold teal--text text-partido2">
          <v-icon
            v-if="value2"
            color="yellow darken-3"
          >
            mdi-gavel
          </v-icon> {{ value2 }}
        </h4><v-icon
          v-if="subText2"
          color="yellow darken-3"
        >
          mdi-decimal
        </v-icon>PORCENTAJE: {{ subText2 }}%
        <!-- </v-icon>PRODUCCIÓN: {{ subText }} -->
      </h4>
    </div>

    <v-col
      cols="12"
      class="px-0"
    >
      <v-divider />
    </v-col>
    <v-icon
      color="red"
      size="16"
      class="ml-2 mr-1"
    >
      mdi-clock
    </v-icon>

    <span
      :class="subTextColor"
      class="text-caption font-weight-light"
    >
      *La evaluación es con respecto al cumplimiento de meta
      <br>Actualizado hasta {{ mes }}
    </span>
  </base-material-card>
</template>

<script>
  import Card from './Card'

  export default {
    name: 'MaterialStatsCard',

    inheritAttrs: false,

    props: {
      ...Card.props,
      icon: {
        type: String,
        required: true,
      },
      subIcon: {
        type: String,
        default: undefined,
      },
      subIconColor: {
        type: String,
        default: undefined,
      },
      subTextColor: {
        type: String,
        default: undefined,
      },
      subText2: {
        type: String,
        default: undefined,
      },
      subText: {
        type: String,
        default: undefined,
      },
      codDep: {
        type: String,
        default: undefined,
      },
      mes: {
        type: String,
        default: undefined,
      },
      title: {
        type: String,
        default: undefined,
      },
      value: {
        type: String,
        default: undefined,
      },
      value2: {
        type: String,
        default: undefined,
      },
      smallValue: {
        type: String,
        default: undefined,
      },
    },

    methods: {
      irJuez (cod) {
        window.open(`https://csjla.pe/judgedata?judged=${cod}`)
      },
    },
  }
</script>

<style lang="sass">
.v-card--material-stats
  display: flex
  flex-wrap: wrap
  position: relative

  > div:first-child
    justify-content: space-between

  .v-card
    border-radius: 4px
    flex: 0 1 auto

  .text-partido
    max-width: 220px

  .text-partido2
    max-width: 220px

  .v-card__text
    display: inline-block
    flex: 1 0 calc(100% - 120px)
    position: absolute
    top: 0
    right: 0
    width: 100%

  .v-card__actions
    flex: 1 0 100%
</style>
